import { Component, ViewChild, Inject, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatSidenav } from '@angular/material';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'hannibal';
  public fixed: boolean = false;
  imageUrlArray = new Array();
  isMobile: boolean = false;
  @ViewChild('snav', { static: false }) sidenav: MatSidenav;
  constructor(private router: Router,
    private deviceService: DeviceDetectorService,
    @Inject(DOCUMENT) private doc: Document) {
    this.isMobile = this.deviceService.isMobile();
    this.imageUrlArray.push("assets/images/HannibalInc.png");
    this.imageUrlArray.push("assets/images/A.jpg");
    this.imageUrlArray.push("assets/images/B.jpg");
    this.imageUrlArray.push("assets/images/C.jpg");
    this.imageUrlArray.push("assets/images/D.jpg");
    this.imageUrlArray.push("assets/images/E.jpg");
    this.imageUrlArray.push("assets/images/F.jpg");
    this.imageUrlArray.push("assets/images/G.jpg");
    this.imageUrlArray.push("assets/images/H.jpg");
    this.imageUrlArray.push("assets/images/I.jpg");
    this.imageUrlArray.push("assets/images/J.jpg");
    this.imageUrlArray.push("assets/images/k.jpg");
    this.imageUrlArray.push("assets/images/l.jpg");
    this.imageUrlArray.push("assets/images/m.jpg");
    this.imageUrlArray.push("assets/images/n.jpg");
    this.imageUrlArray.push("assets/images/o.jpg");
    this.imageUrlArray.push("assets/images/p.jpg");
    this.imageUrlArray.push("assets/images/q.jpg");
    this.imageUrlArray.push("assets/images/r.jpg");
    this.imageUrlArray.push("assets/images/s.jpg");
    this.imageUrlArray.push("assets/images/t.jpg");
    this.imageUrlArray.push("assets/images/u.jpg");
    this.imageUrlArray.push("assets/images/v.jpg");
    this.imageUrlArray.push("assets/images/w.jpg");
    this.imageUrlArray.push("assets/images/x.jpg");
    this.imageUrlArray.push("assets/images/y.jpg");
    this.imageUrlArray.push("assets/images/z.jpg");
    this.imageUrlArray.push("assets/images/1.jpg");
    this.imageUrlArray.push("assets/images/2.jpg");
    this.imageUrlArray.push("assets/images/3.jpg");
    this.imageUrlArray.push("assets/images/4.jpg");
    this.imageUrlArray.push("assets/images/5.jpg");
    this.imageUrlArray.push("assets/images/6.jpg");
    this.imageUrlArray.push("assets/images/7.jpg");
    this.imageUrlArray.push("assets/images/8.jpg");
    this.imageUrlArray.push("assets/images/9.jpg");
  }
  close(reason: string) {
    this.sidenav.close();
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    let top = window.scrollY;
    if (top > 30) {
      this.fixed = true;
    } else if (this.fixed && top < 30) {
      this.fixed = false;
    }
  }
}
