<div class="main">
    <h1>Information Request</h1>
    <form [formGroup]="formGroup" class="example-form">
        <mat-form-field class="form-element">
            <input matInput placeholder="Full Name" formControlName="name">
                <mat-error *ngIf="!formGroup.controls['name'].valid && formGroup.controls['name'].touched">
                    {{ getErrorName() }}
                </mat-error>
        </mat-form-field>
        <mat-form-field class="form-element">
        <input matInput placeholder="Email address" formControlName="email">
        <mat-error *ngIf="!formGroup.controls['email'].valid && formGroup.controls['email'].touched">
            {{ getErrorName() }}
        </mat-error>
    </mat-form-field>
        <mat-form-field class="form-element">
        <textarea matInput placeholder="Message" formControlName="message"></textarea>
        <mat-error *ngIf="!formGroup.controls['message'].valid && formGroup.controls['message'].touched">
            {{ getErrorName() }}
        </mat-error>
    </mat-form-field>
        <button mat-raised-button color="primary" (click)="onSubmit()">Submit</button>
    </form>
    <button mat-stroked-button color="primary" (click)="privacy()">Privacy Policy</button>
</div>