// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDWSyXSUjw_bddKdKHpxCO7sUg5_9bTDqk",
    authDomain: "hannibalincweb.firebaseapp.com",
    databaseURL: "https://hannibalincweb.firebaseio.com",
    projectId: "hannibalincweb",
    storageBucket: "hannibalincweb.appspot.com",
    messagingSenderId: "39884084806",
    appId: "1:39884084806:web:10a6c3f88454c8470b9c47",
    measurementId: "G-L62FZH7RNP"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
