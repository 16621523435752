import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { ProjectsComponent } from './projects/projects.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { MaterialDesignModule } from './material.component';
import { ImageSliderComponent } from './image-slider/image-slider.component';
import { SlideshowModule } from 'ng-simple-slideshow';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { ReactiveFormsModule } from '@angular/forms';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { ToastrModule } from 'ngx-toastr';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

@NgModule({
   declarations: [
      AppComponent,
      HomeComponent,
      ProjectsComponent,
      ContactComponent,
      AboutComponent,
      ImageSliderComponent,
      PrivacyPolicyComponent
   ],
   imports: [
      BrowserModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      MaterialDesignModule,
      HttpClientModule,
      SlideshowModule,
      ReactiveFormsModule,
      DeviceDetectorModule.forRoot(),
      AngularFireModule.initializeApp(environment.firebase),
      //MainAngularfiremodule\nAngularFireDatabaseModule,
      ToastrModule.forRoot()
   ],
   providers: [],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }
