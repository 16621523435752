<div class="main">
        <div fxLayout="row">
                <div fxFlex="20"></div>
                <div fxFlex="60">
                        <p>
                                This is the home for all my cool stuff. 
                        </p>
                </div>
                <div fxFlex></div>
        </div>
</div>