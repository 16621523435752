import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LeadsService } from '../services/leads.service';
import { Lead } from '../.models/Leads.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  formGroup: FormGroup;
  ld: any;
  lead: Lead;

  constructor(private fb: FormBuilder, private leadsService: LeadsService,
    public toastr: ToastrService) {    
    this.createForm();
   }

   createForm() {
    let emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    this.formGroup = this.fb.group({
      name: ['', Validators.required],
      email: [null, [Validators.required, Validators.pattern(emailregex)]],
      message: ['', Validators.required],
    });
    this.formGroup.patchValue(
      {name: 'Michael',
      email: 'mickey_j42@outlook.com',
      message: 'This is a test message'}

    )  
  }
 
  onSubmit() {
    if (this.formGroup.status != 'VALID'){
      console.log('form is not valid, cannot save to database')
      return
    }
    this.lead = this.formGroup.value;
    console.log(this.lead);
    this.leadsService.createLead(this.lead);
    this.toastr.success("Message Sent successfully"); // Show success message when data is successfully submited
    this.formGroup.reset();
    Object.keys(this.formGroup.controls).forEach(key => {
      this.formGroup.get(key).setErrors(null) ;
    });
  }

  ngOnInit() {
  }

  getErrorName(){
    return this.formGroup.get('message').hasError('requred') ? 'Field is required' :
    '';
  }

  getMessageError(){
    return this.formGroup.get('name').hasError('requred') ? 'Field is required' :
    '';
  }

  getErrorEmail() {
    return this.formGroup.get('email').hasError('required') ? 'Field is required' :
      this.formGroup.get('email').hasError('pattern') ? 'Not a valid emailaddress' : '';
  }

  privacy(){
    window.open("assets/Hannibal_Inc_Privacy_Policy.pdf", '_blank');
  }
}
