<div id="container">
  <div id="navi">
    <mat-toolbar [ngClass]="{'toolbar-solid': fixed, 'toolbar': !fixed}">  
      <mat-toolbar-row *ngIf="!isMobile">
        <button mat-button routerLink="/home">
          <mat-icon>home</mat-icon>
          Home</button>
        <button mat-button routerLink="/projects">
          <mat-icon>playlist_add_check</mat-icon>
          Projects</button>
          <span class="example-spacer"></span>
        <span>Hannibal.inc</span>
        <span class="example-spacer"></span>
        <button mat-button routerLink="/contact">
          <mat-icon>phone</mat-icon>
          Contact</button>
        <button mat-button routerLink="/about">
          <mat-icon>help</mat-icon>
          About</button>
      </mat-toolbar-row>
    <mat-toolbar-row *ngIf="isMobile">
      <button mat-button routerLink="/home">
        <mat-icon>home</mat-icon></button>
        <span class="example-spacer"></span>
      <span>Hannibal.inc</span>
      <span class="example-spacer"></span>
      
      <button mat-button [matMenuTriggerFor]="matMainMenu">
        <mat-icon>more_vert</mat-icon></button>
        <mat-menu #matMainMenu="matMenu">
          <button mat-menu-item routerLink="/projects"><mat-icon>playlist_add_check</mat-icon>
            Projects</button>
          <button mat-menu-item routerLink="/contact"> <mat-icon>phone</mat-icon>
            Contact</button>
            <button mat-menu-item routerLink="/about"><mat-icon>help</mat-icon>
              About</button>
        </mat-menu>
    </mat-toolbar-row>
  </mat-toolbar>
  </div>
  <div id="infoi">
    <slideshow [imageUrls]="imageUrlArray" 
      [autoPlay]="true" 
      [showArrows]="false" 
      [lazyLoad]="imageUrlArray?.length > 1"
      [autoPlayWaitForLazyLoad]="true"
      [autoPlayInterval]="5000"
      [stopAutoPlayOnSlide]="false"></slideshow>
    </div>
</div>


<router-outlet></router-outlet>