import { Injectable } from '@angular/core';
import { Lead } from '../.models/Leads.model';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';  // Firebase modules for Database, Data list and Single object

@Injectable({
  providedIn: 'root'
})
export class LeadsService {

  leadsRef: AngularFireList<any>;    // Reference to Student data list, its an Observable
  leadRef: AngularFireObject<any>;   // Reference to Student object, its an Observable too
  

  constructor(private db: AngularFireDatabase) {
    this.leadsRef = db.list('/leads');
   }

  createLead(lead: Lead){
    this.leadsRef.push({
      name: lead.name,
    email: lead.email,
    message: lead.message
    })
  }


  /// Updates an existing Ad
  updateLead(ad: AngularFireObject<Lead>, data: any) {
    return ad.update(data)
  }
}
